<template>
  <CModal :show.sync="show" size="xl" addContentClasses="card" :closeOnBackdrop="false">
    <template slot="header-wrapper">
      <CCardHeader>
        <CIcon name="cil-image1" />
        {{titulo}}
        <CButtonClose @click="fecharVisualizacao()" buttonClasses="text-muted close" />
      </CCardHeader>
    </template>
    <template slot="body-wrapper">
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CRow>
          <CCol sm="9">
            <div ref="vizualizador" id="visualizadorSVG"></div>
          </CCol>
          <CCol sm="3" class="overflow-auto">
            <CTabs variant="tabs" :active-tab="guiaAtivo">
              <CTab>
                <div slot="title">
                  <CIcon name="cil-object-group" />
                </div>
                <CRow>
                  <loader :loading="requisicaoVerificao"></loader>
                  <CCol sm="12">
                    <p class="mt-2 mb-2">
                      <b>Dimensão em plantas</b>
                    </p>
                    <CInput
                      label="Comprimento (a)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.a"
                    />
                    <CInput
                      label="Largura (b)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.b"
                    />
                    <p class="mt-2 mb-2">
                      <b>Altura</b>
                    </p>
                    <CInput
                      label="Altura Rodapé (dr)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.altura_rodape"
                    />
                    <CInput
                      label="Altura Sapata (ds)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.altura"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <div slot="title">
                  <CIcon name="cil-ethernet" />
                </div>
                <CRow>
                  <CCol sm="12">
                    <div v-for="(item, index) in fundacaoAtiva.aco" :key="index">
                      <p class="mt-2 mb-2">
                        <b>N{{item.posicao}}</b>
                      </p>
                      <CInput
                        label="Quantidade"
                        type="number"
                        min="0"
                        v-model.number="fundacaoAtiva.aco[index].quantidade"
                      />
                      <CSelect
                        label="Bitola"
                        :options="bitolasCriterio"
                        :value.sync="fundacaoAtiva.aco[index].bitola"
                      ></CSelect>
                      <CInput
                        label="Espaçamento"
                        type="number"
                        min="0"
                        append="m"
                        v-model.number="fundacaoAtiva.aco[index].espacamento"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CTab>
              <CDropdown color="defatult" :inNav="true">
                <CIcon slot="toggler-content" name="cil-applications-settings"></CIcon>
                <CDropdownItem @click="redefinirEstrutural">Redefinir</CDropdownItem>
                <CDropdownItem>Relatório</CDropdownItem>
              </CDropdown>
            </CTabs>
            <CRow>
              <CCol sm="12">
                <CButton color="info" size="sm" class="float-right" @click="salvarAlteracao">
                  <CIcon name="cil-save"></CIcon>&nbsp;Salvar
                </CButton>
                <CButton color="warning" size="sm" class="float-left" @click="verificarFundacao">
                  <CIcon name="cil-check"></CIcon>&nbsp;Verificar
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </template>
    <template slot="footer-wrapper">
      <CCardFooter>
        <CButton color="success" size="sm" class="float-left">
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar DXF
        </CButton>
      </CCardFooter>
    </template>
  </CModal>
</template>
<script>
import mixinVisualizacao from "../mixinVizualizacao";
export default {
  name: "SapataVisualizacao",
  mixins: [mixinVisualizacao],
  data() {
    return {
      requisicaoAberta: false,
      requisicaoVerificao: false,
      titulo: "",
      guiaAtivo: 0,
      fundacaoAtiva: {
        aco: [
          { quantidade: 0, espacamento: 0, bitola: 5 },
          { quantidade: 0, espacamento: 0, bitola: 5 }
        ]
      }
    };
  },
  methods: {}
};
</script>
<style scoped>
#visualizadorSVG {
  height: 75vh;
}
</style>
