<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-" />
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable :items="fundacoes" :fields="campos" :sorterValue="{column: 'id', asc: 'true'}">
          <td slot="id" slot-scope="{item, index}" class="text-center">{{index+1}}</td>
          <td slot="acoes" slot-scope="{item, index}">
            <CButtonGroup size="sm">
              <CButton color="info" @click="abrirDesenhoSapata(item.id)">
                <CIcon name="cil-image1" />
              </CButton>
              <CButton color="info" @click="abrirGraficoAnalises(item.id)">
                <CIcon name="cil-graph" />
              </CButton>
              <CButton color="danger">
                <CIcon name="cil-trash" />
              </CButton>
            </CButtonGroup>
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
    <SapataVisualizacao :show.sync="sapataVisualizacao" :idFundacao="sapataAtiva"></SapataVisualizacao>
    <GraficoRecalque :show.sync="janelaRecalque" :idFundacao="fundacaoAtiva"></GraficoRecalque>
  </div>
</template>

<script>
//import Meta from "./../../../shared/meta-dados";
import mixin from "./../../../shared/mixin";
import mixinEstrutural from "./../mixinsEstrutural";
import Loader from "@/views/Componentes/loader";
import Axios from "axios";
import SapataVisualizacao from "./SapataVisualizacao";
import GraficoRecalque from "./../GraficoRecalque";

export default {
  name: "EstruturalSapata",
  components: { Loader, SapataVisualizacao, GraficoRecalque },
  mixins: [mixin, mixinEstrutural],
  data() {
    return {
      requisicaoAberta: false,
      fundacao: 1,
      titulo: "Sapata",
      fundacoes: [],
      campos: [
        { key: "id", label: "#", _classes: "text-center" },
        { key: "identificacao", label: "Identificação" },
        { key: "largura_tabela", label: "A (m)" },
        { key: "altura_tabela", label: "B (m)" },
        { key: "altura", label: "Altura(m)" },
        { key: "altura_rodape", label: "Altura Rodapé (m)" },
        { key: "acoes", label: "Ações" }
      ],
      sapataVisualizacao: false,
      sapataAtiva: null
    };
  },
  methods: {
    abrirDesenhoSapata(idSapata) {
      this.sapataAtiva = idSapata;
      this.sapataVisualizacao = true;
      this.$eventoHub.$emit("exibirDesenho", idSapata);
    },
    fecharDesenhoSapata() {
      this.sapataVisualizacao = false;
      this.sapataAtiva = null;
    },
    obtenhaEstrutural() {
      let idProjeto = this.obtenhaIdProjeto();
      let idEstrutural = this.obtenhaIdEstrutural();
      this.requisicaoAberta = true;
      Axios.get("/projeto/" + idProjeto + "/estrutural/" + idEstrutural)
        .then(resposta => {
          if (resposta.data.retorno.fundacao !== this.fundacao) {
            this.$router.push({
              name: "Estrutural",
              params: {
                projetoID: idProjeto
              }
            });
          }
          let sapatas = resposta.data.retorno.fundacoes.map(sapata => {
            sapata["largura_tabela"] =
              sapata.angulo == 90 ? sapata.a : sapata.b;
            sapata["altura_tabela"] = sapata.angulo == 90 ? sapata.b : sapata.a;

            return sapata;
          });
          this.titulo = resposta.data.retorno.identificacao;
          this.fundacoes = sapatas;
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  created() {
    this.obtenhaEstrutural();
    this.$eventoHub.$on("fecharJanelaVisualizacao", this.fecharDesenhoSapata);
  },
  destroyed() {
    this.$eventoHub.$off("fecharJanelaVisualizacao", this.fecharDesenhoSapata);
  }
};
</script>

<style>
</style>
